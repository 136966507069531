const config = {
  apiGateway: {
    REGION: 'ap-southeast-2',
    INTERNAL_API_URL: 'https://g7vfw1di0k.execute-api.ap-southeast-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://to8fndlr79.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.ausgrid.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.ausgrid.forwoodsafety.com',
    WEBSOCKET: 'wss://m658sl3l7l.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
};

export default config;